require('../bootstrap')
require('bxslider/dist/jquery.bxslider')

import autoComplete from '@tarekraafat/autocomplete.js'

jQuery(function () {
    // Initialisation and configuration of the slider
    $('#hero').bxSlider({
        auto: true,
        pause: 10000,
        autoDelay: 10000,
        stopAutoOnClick: true,
        autoHover: true,
        pager: false,
        wrapperClass: 'bx-wrapper-home',
        nextSelector: '.bxNext',
        prevSelector: '.bxPrev',
        prevText: `<img src="/images/icons/chevron_left.svg" class="h-1/3 m-2" height="20" alt="Précédent">`,
        nextText: `<img src="/images/icons/chevron_right.svg" class="h-1/3 m-2" height="20" alt="Suivant">`,
        onSliderLoad: function () {
            $('#slider-area').css('visibility', 'visible')
        },
    })

    // Initialisation and configuration of the search bar
    const autoCompleteJS = new autoComplete({
        selector: '#search',
        placeHolder: 'Rechercher un produit...',
        debounce: 500,
        threshold: 3,
        data: {
            src: async query => {
                let myHeaders = new Headers()
                myHeaders.append('X-Requested-With', 'XMLHttpRequest')

                let myInit = {
                    method: 'GET',
                    headers: myHeaders,
                }

                try {
                    const source = await fetch(`/ajax-search-products?search=${query}`, myInit)
                    return await source.json()
                } catch (error) {
                    return error
                }
            },
            // Data 'Object' key to be displayed
            keys: ['title'],
        },
        searchEngine: 'loose',
        submit: true,
        resultsList: {
            maxResults: 10,
            id: 'autoComplete_list',
            noResults: true,
            element: (list, data) => {
                if (!data.results.length) {
                    $('#autoComplete_list').prepend(`<li class='no_result'>Pas de résultats pour <b>"${data.query}"</b></li>`)
                }
            },
        },
        events: {
            input: {
                focus: () => {
                    if (autoCompleteJS.input.value.length) $('#autoComplete_list').removeAttr('hidden')
                },
                focusout: () => {
                    if (autoCompleteJS.input.value.length) $('#autoComplete_list').attr('hidden', 'true')
                },
                selection: event => {
                    let selection = event.detail.selection.value
                    autoCompleteJS.input.value = selection.title
                    window.location.href = selection.uri
                },
            },
        },
    })
})
